<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div class="flex justify-content-between">
        <h5>Launch Pad</h5>
        <Button
          label="Создать Launch Pad"
          class="p-button-primary mb-4"
          @click="createDialog = true"
        />
      </div>
      <div v-if="items.length" class="my-company">
        <DataTable
          :value="items"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="itemsPerPage"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column header="Name" field="name" style="width: 100px"> </Column>
          <Column header="Site" field="site" style="width: 100px"> </Column>
          <Column header="amountAUt" field="amountAUt"></Column>
          <Column header="amountUSDT" field="amountUSDT"></Column>
          <Column header="code" field="code"></Column>
          <Column header="totalAUt" field="totalAUt"></Column>
          <Column header="totalUSDT" field="totalUSDT"></Column>
          <Column header="Начало" field="startAt">
            <template #body="slot">
              {{
                slot.data.startAt
                  ? moment(slot.data.startAt).format('DD.MM.YYYY')
                  : 'нет данных'
              }}
            </template>
          </Column>
          <Column header="Конец" field="endAt">
            <template #body="slot">
              {{
                slot.data.endAt
                  ? moment(slot.data.endAt).format('DD.MM.YYYY')
                  : 'нет данных'
              }}
            </template>
          </Column>

          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template #body="slot">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning m-1"
                @click="edit(slot.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                @click="del(slot.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-company">
        <p>Not any companies yet</p>
      </div>
    </div>
    <!-- Dialogs -->
    <Dialog
      v-model:visible="createDialog"
      :header="header"
      style="width: 700px"
      class="p-fluid"
      @hide="hide"
    >
      <div class="flex">
        <div class="flex-column col-6">
          <label>Название</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.name" />
          <label>Сайт</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.site" />
          <label>code</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.code" />
          <label>Начало</label>
          <Calendar
            class="mt-1 mb-3"
            v-model="itemCreate.startAt"
            showIcon
            dateFormat="dd.mm.yy"
          />
        </div>
        <div class="flex-column col-6">
          <label>amountAUt</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.amountAUt" />
          <label>amountUSDT</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.amountUSDT" />
          <label>totalAUt</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.totalAUt" />
          <label>totalUSDT</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.totalUSDT" />
          <label id="productname">Конец</label>
          <Calendar
            class="mt-1 mb-3"
            v-model="itemCreate.endAt"
            showIcon
            dateFormat="dd.mm.yy"
          />
        </div>
      </div>

      <div class="distance_button">
        <small class="p-error flex justify-content-center">
          <span v-if="!allField">Поля заполнены не все или не корректно</span>
          <span v-else>&nbsp;</span>
        </small>
        <Button
          :disabled="!allField"
          :label="button"
          class="p-button-primary mt-2"
          @click="editDialog ? editItem() : createItem()"
        />
      </div>
    </Dialog>

    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Удаление"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="itemCreate">
          Вы действительно хотите удалить Launch Pad:
        </p>
        <p class="text-center">
          <b>{{ itemCreate.name }} ?</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="removeItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      items: [],
      itemCreate: {
        name: '',
        site: '',
        amountAUt: '',
        amountUSDT: '',
        code: '',
        totalAUt: '',
        totalUSDT: '',
        startAt: '',
        endAt: '',
      },
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      page: 1,
      itemsPerPage: 30,
      totalItems: 1,
    }
  },
  async mounted() {
    this.getItems()
  },
  methods: {
    moment(date) {
      return moment(date)
    },
    setPage(event) {
      this.page = event.page + 1
      this.getItems()
    },
    async getItems() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/a_ut_launch_pads', {
          headers,
          params,
        })
        this.items = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        console.log(error)
      }
    },

    hide() {
      this.itemCreate = {}
    },

    edit(value) {
      this.itemCreate = { ...value }
      ;(this.itemCreate.startAt = ''),
        (this.itemCreate.endAt = ''),
        (this.editDialog = true)
      this.createDialog = true
      console.log(this.itemCreate)
    },

    async createItem() {
      const params = this.itemCreate
      try {
        await this.axios.post('api/a_ut_launch_pads', params)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Launch Pad created',
          group: 'info',
          life: 3000,
        })
        this.getItems()
        this.editDialog = false
        this.createDialog = false
      } catch (e) {
        this.editDialog = false
        this.createDialog = false
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Launch Pad not created',
          group: 'info',
          life: 3000,
        })
      }
    },
    async editItem() {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      }
      const id = this.itemCreate.id
      const params = this.itemCreate
      try {
        await this.axios.patch(`api/a_ut_launch_pads/${id}`, params, {
          headers: headers,
        })
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Launch Pad changed',
          group: 'info',
          life: 3000,
        })
        this.getItems()
        this.editDialog = false
        this.createDialog = false
      } catch (e) {
        this.editDialog = false
        this.createDialog = false
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Launch Pad not changed',
          group: 'info',
          life: 3000,
        })
      }
    },

    del(value) {
      this.itemCreate = { ...value }
      this.deleteDialog = true
    },

    async removeItem() {
      const id = this.itemCreate.id
      try {
        await this.axios.delete(`api/a_ut_launch_pads/${id}`)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Launch Pad deleted',
          group: 'info',
          life: 3000,
        })
        this.getItems()
        this.deleteDialog = false
      } catch (e) {
        this.deleteDialog = false
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Launch Pad not delete',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
  computed: {
    allField() {
      return (
        this.itemCreate.name?.length &&
        this.itemCreate.site?.length &&
        this.itemCreate.amountAUt?.length &&
        this.itemCreate.amountUSDT?.length &&
        this.itemCreate.code?.length &&
        this.itemCreate.totalAUt?.length &&
        this.itemCreate.totalUSDT?.length &&
        this.itemCreate.startAt &&
        this.itemCreate.endAt &&
        this.itemCreate.startAt < this.itemCreate.endAt
      )
    },
    header() {
      return this.editDialog ? 'Редактировать Launch Pad' : 'Создать Launch Pad'
    },
    button() {
      return this.editDialog ? 'Редактировать' : 'Создать'
    },
  },
}
</script>

<style scoped>
.bange {
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
}
</style>
